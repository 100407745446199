import React from "react";
import ReactMarkdown from "react-markdown";
interface SectionHeaderProps {
  title: string;
  description?: string | null;
  centered?: boolean;
  className?: string;
  descriptionClassName?: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  description,
  centered = true,
  className="",
  descriptionClassName="px-10 w-1/2 mx-auto font-light text-center"
}) => {
  return (
    <div className="w-full mb-12 flex flex-col items-center">
      <ReactMarkdown children={title} className={`${centered ? "text-center" : "lg:text-left "} 
      lg:font-title font-title-mobile text-section-title-mobile md:text-2xl
      leading-none ${className}`
      }/>
      {
        description && (
          <h3
          className={descriptionClassName}
          >{description}</h3>
        )
      }
    </div>
  );
};

export default SectionHeader;
