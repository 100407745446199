import React from "react";
import { GetFooterQuery } from "./../../../graphql-types";
import { graphql, useStaticQuery, navigate } from "gatsby";
import { MenuRoutes } from "../../constants";

const Footer: React.FC = () => {
  const queryResults = useStaticQuery<GetFooterQuery>(query);

  const legalDocs = queryResults.legalDocuments.edges.map(
    (edge) => edge.node
  );

  const socials = queryResults.socials.edges.map(
    (edge) => edge.node
  );

  const cities = queryResults.cities.edges.map(
    (edge) => edge.node.location
  );

  const headerStyle = "font-sans font-semibold"

  return (
    <>
      {/* Desktop */}
      <div className="hidden md:grid grid-rows-2 border-t border-orange pt-16 mt-52">
        <div className="grid grid-cols-5 gap-7">
          <div>
            <div className={headerStyle}>Office Locations</div>
            <div className="text-sm text-gray-dark font-sans pt-7">
              {cities.map((city) => (
                <div className="min-h-[48px]" key={city}>{city}</div>
              ))}
            </div>
          </div>
          <div>
            <div className={headerStyle}>BAM Elevate</div>
            <div className="text-sm text-gray-dark font-sans pt-7">
              {Object.entries(MenuRoutes).map(([_, menuItem]) => {
                return (
                  <div key={menuItem.name}><div className="block min-h-[48px]">
                    <div className="cursor-pointer w-fit" onClick={() => navigate(menuItem.path)}>{menuItem.name}</div>
                  </div></div>
                );
              })}</div>
          </div>
          <div className="col-span-2">
            <div className={headerStyle}>Legal Documents</div>
            <div className="text-sm text-gray-dark font-sans pt-7">
              {legalDocs.map((doc) => {
                let url = doc.relativePath || undefined;
                if (url && doc.title !== 'Portfolio Company Platform Terms of Use') {
                  return (
                    <div key={doc.id}><div className="block min-h-[48px]">
                      <div className="cursor-pointer w-fit" onClick={() => navigate(`/${url}`)}>{doc.title}</div>
                    </div></div>
                  );
                }
                return null;
              })}
            </div>
          </div>
          <div className={headerStyle}>
            <div >Social</div>
            <div className="pt-7 flex">
            {
              socials.map(item => {
                return (
                  <a className="pr-3" href={item.url} target="_blank" key={item.url}>
                    <img alt={item.url} src={item.icon?.localFile?.publicURL} className="grayscale opacity-70 h-[25px] w-[25px]" />
                  </a>
                )
              })
            }
            </div>
          </div>
        </div>

        <div className="grid content-end text-sm text-gray-dark font-sans py-6">
          &copy; 2022 Balyasny Asset Management L.P. All Rights Reserved.
      </div>
      </div>

      {/* Mobile */}
      <div className="md:hidden flex flex-col border-t border-orange pt-16 mt-28">
        <div>
          <div className={headerStyle}>Office Locations</div>
          <div className="text-sm text-gray-dark font-sans pb-7 pt-3">
            {cities.map((city) => (
              <div className="" key={city}>{city}</div>
            ))}
          </div>
        </div>
        <div>
          <div className={headerStyle}>BAM Elevate</div>
          <div className="text-sm text-gray-dark font-sans pb-7 pt-3">
            {Object.entries(MenuRoutes).map(([_, menuItem]) => {
              return (
                <div key={menuItem.name}><div className="block cursor-pointer" onClick={() => navigate(menuItem.path)}>
                  {menuItem.name}
                </div></div>
              );
            })}</div>
        </div>
        <div>
          <div className={headerStyle}>Legal Documents</div>
          <div className="text-sm text-gray-dark font-sans pb-7 pt-3">
            {legalDocs.map((doc) => {
              let url = doc.relativePath || undefined;
              if (url && doc.title !== 'Portfolio Company Platform Terms of Use') {
                return (
                  <div key={doc.id}><div className="cursor-pointer" onClick={() => navigate(`/${url}`)}>
                    {doc.title}
                  </div></div>
                );
              }
              return null;
            })}
          </div>
        </div>
        <div>
          <div className={headerStyle}>Social</div>
            <div className="flex pt-3">
            { 
              socials.map(item => {
                return (
                  <a className="pr-2" href={item.url} target="_blank" key={item.url}>
                    <img alt={item.url} src={item.icon?.localFile?.publicURL} className="grayscale opacity-70 h-[25px] w-[25px]" />
                  </a>
                )
              })
            }
          </div>
        </div>
        <div className="grid content-end text-xs text-gray-dark font-sans py-7">
          &copy; 2022 Balyasny Asset Management L.P. All Rights Reserved.
      </div>
      </div>
    </>
  );
};

export default Footer;

const query = graphql`
  query GetFooter {
    legalDocuments: allStrapiLegalDocument {
      edges {
        node {
          id
          title
          relativePath
        }
      }
    }
    socials: allStrapiSocial(sort: {fields: strapi_id}) {
      edges {
        node {
          url
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    cities: allStrapiCity(sort: {fields: location, order: ASC}) {
      edges {
        node {
          location
        }
      }
    }
  }
`;
