import React from "react";

interface PageContentProps {
  className?: string;
  children: Array<JSX.Element> | JSX.Element;
  padding?: string;
}

const PageContent: React.FC<PageContentProps> = ({
  className = "",
  children,
  padding = "px-5 md:px-12"
}) => {
  return (
    <div className={`w-full bg-off-white mt-16 md:mt-32 ${padding} ${className}`}>
        {children}
    </div>
  );
};

export default PageContent;
