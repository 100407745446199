type ErrorCode = "CMS_DATA_MISSING" | "IMAGE_DATA_MISSING";

/**
 * Helper function for dealing with undefined or null checks
 * for fields that MUST be defined, otherwise it is unrecoverable.
 *
 * An example is the footerImage. If this is undefined or null we have bigger problems
 * with the build and need to stop the build all together.
 *
 * Make sure to call the `otherwise` function on the result of this function for actually
 * handling the result.
 *
 * Example:
 *
 * ```js
 * const apiResult = undefined;
 * const requiredValue = mustBeDefined(apiResult).otherwise(...)
 * ```
 */
export const mustBeDefined = <T>(
  item: T | null | undefined
): MustBeDefinedResult<T> => {
  return new MustBeDefinedResult<T>(item);
};

const getErrorMessageHeader = (type: ErrorCode): string => {
  switch (type) {
    case "CMS_DATA_MISSING":
      return `${type} - Data seems to missing from the CMS. This can happen when the query is invalid or the CMS is missing data for required fields.`;
    case "IMAGE_DATA_MISSING":
      return `${type} - Data seems to missing from the filesystem. This can happen when gatsby-source-filesystem is not configured properly, or assets are not present in the assets folder.`;
  }
};

class MustBeDefinedResult<T> {
  private obj: T | null | undefined;
  constructor(obj: T | null | undefined) {
    this.obj = obj;
  }

  otherwise = (error: ErrorCode, moreInfo: string): T => {
    if (!this.obj) {
      throw new Error(`${getErrorMessageHeader(error)} Related: ${moreInfo}`);
    } else {
      return this.obj;
    }
  };
}

/**
 * Estimate how many minutes it will take to read an article
 *
 * The average reading speed is 200 words per minute
 */
export const timeToRead = (text: string): number => {
  const averageWordsPerMinute = 200;
  const wordCount = text.split(" ").length;
  return Math.ceil(wordCount / averageWordsPerMinute);
};
